export interface BlogMainSectionTypes {
  title: string;
  textContent: string;
  sections: SectionItem[];
}

export interface SectionItem {
  sectionContent: string;
  sectionImage: string;
}

export interface BlogHeaderTypes {
  headerData: {
    id?: number;
    headerTitle: string;
    headerContent: string;
    mainImage: string;
    bodyTitle: string;
    videoLink?: string;
  };
}

export interface BlogItem {
  id: number;
  headerTitle: string;
  headerContent: string;
  mainImage?: string | null;
}

export const MediaTypeEnum = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  CAROUSEL_ALBUM: 'CAROUSEL_ALBUM',
};

export type InstagramFeedItem = {
  id: string;
  caption?: string;
  media_url: string;
  media_type: keyof typeof MediaTypeEnum;
  timestamp: string;
  permalink: string;
};

export type InstagramFeedResponse = {
  data: InstagramFeedItem[];
  paging?: {
    cursors?: {
      before?: string;
      after?: string;
    };
    next?: string;
  };
};
export type SocialMediaProps = {
  instagramData: InstagramFeedResponse;
};
