'use client';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/layout';

import LinkCustom from '@/app/_components/link/LinkCustom';

import Styles from './LatestNews.module.scss';
import NewsCard from './NewsCard';

type LatestNewsProps = {
  date_to_publish: string;
  mainImage: string | null;
  headerTitle: string;
  slug: string;
}[];

const LatestNews = ({
  latestNewsBlogs,
}: {
  latestNewsBlogs: LatestNewsProps;
}) => {
  return (
    <>
      <Box className={Styles.latestNewsWrapper} paddingY={'100px'}>
        <Container maxW={1280} width={'100%'} paddingX={{ base: 6, '2xl': 0 }}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            flexWrap={'wrap'}
            alignItems={{ base: 'start', md: 'center' }}
            marginBottom={{ base: 16, md: 10 }}
            className={Styles.latestNewsHeader}
          >
            <Box className={Styles.headingElement}>
              <Text fontSize="xs" textTransform={'uppercase'} marginBottom={4}>
                Must Read Articles
              </Text>
              <Heading
                as="h2"
                textTransform={'capitalize'}
                className={Styles.titleLg}
              >
                Latest News
              </Heading>
            </Box>
            <Box
              mt={{ base: 8, md: 0 }}
              marginLeft={{ md: 'auto' }}
              className={Styles.latestNewsHeaderCTA}
            >
              <LinkCustom
                role="link"
                aria-label="View All"
                className="btnOutline"
                href="/blog/latest-news"
              >
                View all
                <ChevronRightIcon width={4} height={4} marginLeft={3} />
              </LinkCustom>
            </Box>
          </Flex>
          <SimpleGrid columns={[1, 1, 2, 3]} gap={10}>
            {latestNewsBlogs?.map((blog, i) => (
              <NewsCard
                key={i}
                image={blog?.mainImage}
                title={blog?.headerTitle}
                date_to_publish={blog?.date_to_publish}
                slug={blog?.slug ?? ''}
                color="black"
              />
            ))}
          </SimpleGrid>
          <Box className={Styles.latestNewsHeaderCTAmobile}>
            <LinkCustom
              role="link"
              aria-label="View All"
              className="btnOutline"
              moduleClass={Styles.btnCTA}
              href="/blog/latest-news"
            >
              View all
              <ChevronRightIcon width={4} height={4} marginLeft={3} />
            </LinkCustom>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LatestNews;
